<template>
  <div class="login-body">
    <div class="card login-panel">
      <div class="login-panel-content">
        <div class="grid mt-4">
          <Card class="p-fluid col-12">
            <template v-slot:content>
              <div class="col-12 px-5">
                <img src="/layout/images/cepi/logo-cepi-web-dark.png" class="img-responsive" alt="GADCH"
                  style="width: 15%; height: auto" />
              </div>
              <div class="col-12">
                <h2>Registro Docentes</h2>
              </div>
              <!-- Paso 1 - Registro de Datos -->
              <div v-if="activeContent == 'datos'" class="grid p-3 step-content datos"
                :class="{ 'active-content': activeContent === 'datos' }">
                <div class="grid">
                  <div class="col-12 md:col-12">
                    <h5 class="text-left">Datos Personales</h5>
                    <div class="grid formgrid text-left">
                      <div class="field col-6 md:col-3">
                        <label for="documento"><span class="color-danger">[*]</span> No. de Identificación</label>
                        <InputText v-model="formDatos.documento" id="documento" v-uppercase type="text" />
                        <small v-if="erroresDatos?.documento" class="p-error">{{ erroresDatos.documento }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="expedido"><span class="color-danger">[*]</span> Expedido en</label>
                        <VueSelect v-model="formDatos.expedido" :options="departamentos" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.expedido" class="p-error">{{ erroresDatos.expedido }}</small>
                      </div>
                      <div class="field col-6 md:col-3">
                        <label for="nombres"><span class="color-danger">[*]</span> Nombres</label>
                        <InputText v-model="formDatos.nombres" v-uppercase id="nombres" type="text" />
                        <small v-if="erroresDatos?.nombres" class="p-error">{{ erroresDatos.nombres }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="apellidoPaterno"><span class="color-danger">[*]</span> Apellido Paterno</label>
                        <InputText v-model="formDatos.apellidoPaterno" v-uppercase id="apellidoPaterno" type="text" />
                        <small v-if="erroresDatos?.apellidoPaterno" class="p-error">{{ erroresDatos.apellidoPaterno
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="apellidoMaterno"><span class="color-danger">[*]</span> Apellido Materno</label>
                        <InputText v-model="formDatos.apellidoMaterno" v-uppercase id="apellidoMaterno" type="text" />
                        <small v-if="erroresDatos?.apellidoMaterno" class="p-error">{{ erroresDatos.apellidoMaterno
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-3">
                        <label for="sexo"><span class="color-danger">[*]</span> Sexo</label>
                        <VueSelect v-model="formDatos.sexo" :options="sexoOpciones" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.sexo" class="p-error">{{ erroresDatos.sexo }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="paisOrigen"><span class="color-danger">[*]</span> País de Origen</label>
                        <VueSelect v-model="formDatos.paisOrigen" :options="paises" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.paisOrigen" class="p-error">{{ erroresDatos.paisOrigen }}</small>
                      </div>
                      <div class="field col-6 md:col-3">
                        <label for="departamentoOrigen"><span class="color-danger">[*]</span> Departamento de
                          Origen</label>
                        <VueSelect v-model="formDatos.departamentoOrigen" :options="departamentos" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.departamentoOrigen" class="p-error">{{
                          erroresDatos.departamentoOrigen }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="fechaNacimiento"><span class="color-danger">[*]</span> Fecha de Nacimiento</label>
                        <Calendar v-model="formDatos.fechaNacimiento" dateFormat="dd/mm/yy" :maxDate="fechaHoy"
                          :showIcon="true" :showButtonBar="true" />
                        <small v-if="erroresDatos?.fechaNacimiento" class="p-error">{{ erroresDatos.fechaNacimiento
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="estadoCivil"><span class="color-danger">[*]</span> Estado Civil</label>
                        <VueSelect v-model="formDatos.estadoCivil" :options="estadosCivil" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.estadoCivil" class="p-error">{{ erroresDatos.estadoCivil }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="discapacidad"><span class="color-danger">[*]</span> ¿Tiene alguna
                          discapacidad?</label>
                        <div class="flex gap-3 align-items-center">
                          <RadioButton inputId="discapacidad-si" v-model="formDatos.tieneDiscapacidad" :value="true"
                            class="p-radiobutton" />
                          <label for="discapacidad-si" class="ml-2">Sí</label>

                          <RadioButton inputId="discapacidad-no" v-model="formDatos.tieneDiscapacidad" :value="false"
                            class="p-radiobutton" />
                          <label for="discapacidad-no" class="ml-2">No</label>
                        </div>
                        <small v-if="erroresDatos?.tieneDiscapacidad" class="p-error">{{ erroresDatos.tieneDiscapacidad
                          }}</small>
                      </div>
                      <div v-if="formDatos.tieneDiscapacidad" class="field col-6 md:col-2">
                        <label for="tipoDiscapacidad"><span class="color-danger">[*]</span> ¿Cuál?</label>
                        <VueSelect v-model="formDatos.tipoDiscapacidad" :options="tiposDiscapacidad" label="nombre"
                          placeholder="- Seleccionar -" class="white-background" />
                        <small v-if="erroresDatos?.tipoDiscapacidad" class="p-error">{{ erroresDatos.tipoDiscapacidad
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-3">
                        <label for="profesion"><span class="color-danger">[*]</span> Profesión</label>
                        <InputText v-model="formDatos.profesion" id="profesion" type="text" />
                        <small v-if="erroresDatos?.profesion" class="p-error">{{ erroresDatos.profesion }} </small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="nivelAcademico"><span class="color-danger">[*]</span> Nivel Académico</label>
                        <VueSelect v-model="formDatos.nivelAcademico" :options="nivelAcademicoOpciones" label="nombre"
                          placeholder="- Seleccionar -" class="white-background w-full" />
                        <small v-if="erroresDatos?.nivelAcademico" class="p-error">{{ erroresDatos.nivelAcademico
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-3">
                        <label for="lugarTrabajo"><span class="color-danger">[*]</span> Lugar Trabajo</label>
                        <InputText v-model="formDatos.lugarTrabajo" id="lugarTrabajo" type="text" />
                        <small v-if="erroresDatos?.lugarTrabajo" class="p-error">{{ erroresDatos.lugarTrabajo }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="telefonoOficina"><span class="color-danger">[*]</span>Teléfono Oficina</label>
                        <InputText v-model="formDatos.telefonoOficina" id="telefonoOficina" type="text" />
                        <small v-if="erroresDatos?.telefonoOficina" class="p-error">{{ erroresDatos.telefonoOficina
                          }}</small>
                      </div>
                    </div>
                    <h5 class="text-left">Datos de Residencia</h5>
                    <div class="grid formgrid text-left">
                      <div class="field col-6 md:col-2">
                        <label for="paisResidencia"><span class="color-danger">[*]</span> País</label>
                        <VueSelect v-model="formDatos.paisResidencia" :options="paises" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.paisResidencia" class="p-error">{{ erroresDatos.paisResidencia
                          }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="departamentoResidencia"><span class="color-danger">[*]</span> Departamento</label>
                        <VueSelect v-model="formDatos.departamentoResidencia" :options="departamentos"
                          @option:selected="getMunicipios" label="nombre" placeholder="- Seleccionar -"
                          class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.departamentoResidencia" class="p-error">{{
                          erroresDatos.departamentoResidencia }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="municipioResidencia"><span class="color-danger">[*]</span> Municipio</label>
                        <VueSelect v-model="formDatos.municipioResidencia" :options="municipios" label="nombre"
                          placeholder="- Seleccionar -" class="white-background"></VueSelect>
                        <small v-if="erroresDatos?.municipioResidencia" class="p-error">{{
                          erroresDatos.municipioResidencia }}</small>
                      </div>
                      <div class="field col-6 md:col-4">
                        <label for="direccion"><span class="color-danger">[*]</span> Dirección</label>
                        <InputText v-model="formDatos.direccion" id="direccion" type="text" />
                        <small v-if="erroresDatos?.direccion" class="p-error">{{ erroresDatos.direccion }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="telefono">Teléfono</label>
                        <InputText v-model="formDatos.telefono" v-onlynumbers id="telefono" type="text" />
                        <small v-if="erroresDatos?.telefono" class="p-error">{{ erroresDatos.telefono }}</small>
                      </div>
                      <div class="field col-6 md:col-2">
                        <label for="celular"><span class="color-danger">[*]</span> Celular</label>
                        <InputText v-model="formDatos.celular" v-onlynumbers id="celular" type="text" />
                        <small v-if="erroresDatos?.celular" class="p-error">{{ erroresDatos.celular }}</small>
                      </div>
                    </div>
                    <h5 class="text-left">Cuenta</h5>
                    <div class="grid formgrid text-left">
                      <div class="field col-6 md:col-4">
                        <label for="correo"><span class="color-danger">[*]</span> Correo</label>
                        <InputText v-model="formDatos.correo" v-email id="correo" type="text" />
                        <small v-if="erroresDatos?.correo" class="p-error">{{ erroresDatos.correo }}</small>
                      </div>
                      <div class="field col-12 md:col-3">
                        <label for="password"><span class="color-danger">[*]</span> Contraseña</label>
                        <Password v-model="formDatos.password" :class="{ 'p-invalid': erroresDatos?.password }"
                          :feedback="false" toggleMask />
                        <small v-if="erroresDatos?.password" class="p-error">{{ erroresDatos.password }}</small>
                      </div>
                      <div class="field col-12 md:col-3">
                        <label for="confirmPassword"><span class="color-danger">[*]</span> Confirmar la
                          Contraseña</label>
                        <Password v-model="formDatos.confirmPassword"
                          :class="{ 'p-invalid': erroresDatos?.confirmPassword }" :feedback="false" toggleMask />
                        <small v-if="erroresDatos?.confirmPassword" class="p-error">{{
                          erroresDatos.confirmPassword }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Paso 2 - Validación del correo -->
              <div v-if="activeContent === 'validacion'"
                :class="{ 'active-content': activeContent === 'validacion', 'grid formgrid': true }">
                <p class="mt-6 text-gray-800 text-xl">
                  Te enviamos un código a tu email. Por favor, ingrésalo para confirmar tu registro. <br />
                  <span class="text-sm text-gray-800">
                    Si no recibiste el código, puedes solicitar que te lo reenviemos.
                  </span>
                  <button @click="reenviarCodigo" :disabled="isCooldownActive || isLoading" :class="{
                    'text-sm py-1 mx-3 cursor-pointer border-none': true,
                    'bg-blue-500 text-white': !isCooldownActive,
                    'bg-blue-400 text-gray-200 cursor-not-allowed': isCooldownActive
                  }">
                    Reenviar código <span v-if="isCooldownActive">({{ cooldownTime }}s)</span>
                  </button>
                </p>
                <div>
                  <InputText v-model="codigo" type="text" placeholder="Ingresa tu código" class="my-3 w-auto" /><br />
                  <Button v-if="activeContent === 'validacion'" :loading="isLoadingRegister" label="Verificar"
                    @click="clickNext(activeContent)" icon="pi pi-check" iconPos="right" class="w-auto" />
                </div>
              </div>
              <!-- Paso 3 - Registro -->
              <div v-if="activeContent === 'registro'" class="animate-bounce mt-8">
                <i class="pi pi-check-circle text-green-500 text-8xl"></i>
                <p class="mt-4 text-xl font-bold text-green-500">¡Verificación exitosa! Ahora puedes completar tu
                  registro.</p>
              </div>
              <div>
                <div class="flex justify-content-end flex-wrap mt-5 mb-6">
                  <div>
                    <Button v-if="activeContent == 'validacion' || activeContent === 'registro'" class="w-max"
                      :loading="isLoading" label="Registrar" :disabled="registrar == false"
                      @click="clickNext(activeContent)" icon="pi pi-check" iconPos="right" />
                    <Button v-else class="w-min" label="Siguiente" :loading="isLoading"
                      @click="clickNext(activeContent)" icon="pi pi-arrow-right" iconPos="right" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <div class="col-12">
            <small class="text-center">© 2023 SIAC - CEPI v1.0.0</small>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import { DocenteService } from '../../service/DocenteService';
import { datosSchema } from './validations/datosValidate';
import { registroSchema } from './validations/registroValidate';
import { AuthService } from '../../service/AuthService';
import { useAuthStore } from '../../stores/authStore';
import { crearVerificacionEmail, verificarCodigo } from '../../service/VerificacionEmailService';

export default {
  name: 'RegistroDocentes',

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      edicion: false,
      load: false,
      isLoading: false,
      isLoadingRegister: false,
      errorStep: false,
      fechaHoy: new Date(),
      activeContent: 'datos',
      registrar: false,
      version: null,
      codigo: null,
      nivelAcademicoOpciones: [],

      isCooldownActive: false,
      cooldownTime: 60,

      formDatos: {
        documento: null,
        complemento: null,
        expedido: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        sexo: null,
        paisOrigen: null,
        departamentoOrigen: null,
        fechaNacimiento: new Date(),
        estadoCivil: null,
        paisResidencia: null,
        tieneDiscapacidad: null,
        tipoDiscapacidad: null,
        departamentoResidencia: null,
        municipioResidencia: null,
        direccion: null,
        telefono: null,
        celular: null,
        correo: null,

        nivelAcademico: null,
        profesion: null,
        lugarTrabajo: null,
        telefonoOficina: null,

        password: null,
        confirmPassword: null,
      },
      erroresDatos: null,
      erroresRegistro: null,

      estadosCivil: [],
      sexoOpciones: ['Masculino', 'Femenino'],
      paises: [],
      departamentos: [],
      municipios: [],
      tiposDiscapacidad: [],
    };
  },
  async mounted() {
    this.load = false;
    await this.getPaises();
    await this.getTiposDiscapacidades();
    await this.getDepartamentos();
    await this.getEstadosCivil();
    await this.getMunicipios();
    this.getNivelAcademico();
    this.load = true;
  },
  methods: {
    async getPaises() {
      this.paises = await DocenteService.paises();
    },
    async getTiposDiscapacidades() {
      this.tiposDiscapacidad = await DocenteService.tiposDiscapacidades();
    },
    async getDepartamentos() {
      this.departamentos = await DocenteService.departamentos();
    },
    async getMunicipios() {
      if (this.formDatos.departamentoResidencia && this.formDatos.departamentoResidencia.id) {
        this.municipios = await DocenteService.municipios(this.formDatos.departamentoResidencia.id);
      } else {
        console.error('departamentoResidencia no está definido o no tiene un ID.');
        this.municipios = [];
      }
    },
    async getEstadosCivil() {
      this.estadosCivil = await DocenteService.estadosCivil();
    },
    async getNivelAcademico() {
      this.nivelAcademicoOpciones = await DocenteService.nivelesAcademicos();
    },
    async clickNext(step) {
      switch (step) {
        case 'datos': {
          await this.validarDatos();
          if (!this.errorStep) {
            await this.registrarDocente();
            if (this.errorStep) {
              this.activeContent = 'datos';
              break;
            }
            let nombres = this.formDatos.nombres + ' ' + this.formDatos.apellidoPaterno + ' ' + this.formDatos.apellidoMaterno;
            nombres = nombres.toLocaleUpperCase();
            await crearVerificacionEmail(this.formDatos.correo, nombres);
            this.startCooldown();
            this.$toast.add({ severity: 'success', summary: 'Mensaje', detail: 'Se ha enviado un código de verificación a tu correo. ', life: 5000 });
          }
          !this.errorStep ? (this.activeContent = 'validacion') : (this.activeContent = step);
          break;
        }
        case 'validacion': {
          this.isLoadingRegister = true;
          let res = await verificarCodigo(this.codigo);
          if (res.transaction) {
            this.isLoadingRegister = false;
            this.activeContent = 'registro';
            this.registrar = true;
            this.$toast.add({ severity: 'success', summary: 'Mensaje', detail: `${res.success}. `, life: 5000 });
          } else {
            this.$toast.add({ severity: 'error', summary: 'Mensaje', detail: `${res.error}. `, life: 5000 });
            this.isLoadingRegister = false;
          }
          break;
        }
        case 'registro':
          this.$router.push('/');
          break;
      }
    },
    async validarDatos() {
      await datosSchema
        .validate(this.formDatos, { abortEarly: false })
        .then(() => {
          this.errorStep = false;
          this.erroresDatos = {};
        })
        .catch((errors) => {
          const formattedErrors = {};
          errors.inner.forEach((error) => {
            formattedErrors[error.path] = error.message;
          });

          this.erroresDatos = formattedErrors;
          this.errorStep = true;
        });
    },
    async reenviarCodigo() {
      if (this.isCooldownActive) return;
      let nombres = this.formDatos.nombres + ' ' + this.formDatos.apellidoPaterno + ' ' + this.formDatos.apellidoMaterno;
      nombres = nombres.toLocaleUpperCase();
      await crearVerificacionEmail(this.formDatos.correo, nombres);

      this.startCooldown();
    },
    startCooldown() {
      this.isCooldownActive = true;
      this.cooldownTime = 60;
      const interval = setInterval(() => {
        if (this.cooldownTime > 0) {
          this.cooldownTime--;
        } else {
          this.isCooldownActive = false;
          clearInterval(interval);
        }
      }, 1000);
    },
    async registrarDocente() {
      let errorValidacion = false;
      const dataValidate = {
        correo: this.formDatos.correo.trim().toLowerCase(),
        //sesionGoogle: this.sesionGoogle,
        password: this.formDatos.password,
        confirmPassword: this.formDatos.confirmPassword,
      };
      await registroSchema
        .validate(dataValidate, { abortEarly: false })
        .then(() => {
          errorValidacion = false;
          this.errorStep = false;
          this.erroresRegistro = {};
        })
        .catch((errors) => {
          const formattedErrors = {};
          errors.inner.forEach((error) => {
            formattedErrors[error.path] = error.message;
          });

          this.erroresRegistro = formattedErrors;
          this.errorStep = true;
          errorValidacion = true;
        });
      if (!errorValidacion) {
        try {
          let data = {
            email: this.formDatos.correo.trim().toLowerCase(),
            password: this.formDatos.password.trim(),
            nombres: this.formDatos.nombres,
            apellidoPaterno: this.formDatos.apellidoPaterno,
            apellidoMaterno: this.formDatos.apellidoMaterno,
            numeroIdentificacion: this.formDatos.documento,
            direccion: this.formDatos.direccion,
            telefono: this.formDatos.telefono,
            celular: this.formDatos.celular,
            expedido: this.formDatos.expedido.abreviacion,
            sexo: this.formDatos.sexo,
            paisOrigenId: this.formDatos.paisOrigen.id,
            departamentoOrigenId: this.formDatos.departamentoOrigen.id,
            fechaNacimiento: this.formDatos.fechaNacimiento,
            paisId: this.formDatos.paisResidencia.id,
            municipioId: this.formDatos.municipioResidencia.id,
            estadoCivilId: this.formDatos.estadoCivil.id,
            tieneDiscapacidad: this.formDatos.tieneDiscapacidad,
            tipoDiscapacidadId: this.formDatos.tipoDiscapacidad ? this.formDatos.tipoDiscapacidad.id : null,
            nivelAcademicoId: this.formDatos.nivelAcademico ? this.formDatos.nivelAcademico.id : null,
            profesion: this.formDatos.profesion,
            lugarTrabajo: this.formDatos.lugarTrabajo,
            telefonoOficina: this.formDatos.telefonoOficina,
          };
          const responseRegistro = await AuthService.registrar(data);
          if (responseRegistro.data?.transaccion == true) {
            this.errorStep = false;
            this.erroresRegistro = {};
          } else {
            this.$toast.add({ severity: 'error', summary: 'Mensaje', detail: `${responseRegistro.data.mensaje}. `, life: 3000 });
            this.errorStep = true;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.step-content {
  display: none;
}

.active-content {
  display: block;
}

.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  padding: 20px;
}

.login-panel .login-panel-content {
  width: 80%;
  left: 23%;
}

.white-background {
  background-color: white;
  border-radius: 5px;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}
</style>
