import { apiInstance } from './ApiInstance';

const generateVerificationToken = async (email, accion) => {
  let data = { email: email };
  const generateNumericToken = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };
  const token = generateNumericToken();
  const expires = new Date(new Date().getTime() + 3600 * 1000);
  const response = await apiInstance.post('/verificar-email/email', data);
  if (response.data.transaccion) {
    await apiInstance.delete(`/verificar-email/${response.data.data.id}`);
  }
  data = {
    email: email,
    codigo: token,
    expiracion: expires,
    tipo: accion,
  }
  const tokenVerification = await apiInstance.post('/verificar-email', data);
  return tokenVerification.data;
};

export const crearVerificacionEmail = async (email, nombres) => {
  try {
    const verificationToken = await generateVerificationToken(email, 'verificacion');
    let data = {
      email: verificationToken.data.email,
      codigo: verificationToken.data.codigo,
      nombres: nombres,
    }
    const resp = await apiInstance.post('/verificar-email/enviar-verificacion', data);
    return { exito: resp.data.exito, error: resp.data.error, transaccion: resp.data.transaccion };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const verificarCodigo = async (codigo) => {
  try {
    let data = { codigo: codigo };
    const response = await apiInstance.post('/verificar-email/codigo', data);
    if (!response.data.transaccion) {
      return { error: 'El código de verificación ingresado es incorrecto. Por favor, verifica el código en tu correo e inténtalo nuevamente. ', transaction: false };
    }
    const dataRep = await response.data;
    const existingToken = dataRep.data;
    const hasExpired = new Date(existingToken.expiracion) < new Date();
    if (hasExpired) {
      return { error: '¡El código ha caducado!', transaction: false };
    }
    await apiInstance.delete(`/verificar-email/${existingToken.id}`);
    return { success: '¡Correo verificado!', transaction: true };
  } catch (error) {
    console.error(error);
    return null;
  }
};
