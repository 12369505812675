import { apiInstance } from './ApiInstance';

class AuthService {
  static login(correo, password) {
    return apiInstance.post('/auth/login', { email: correo, password: password });
  }
  static registrar(data) {
    return apiInstance.post('/auth/register', data);
  }
  static refresToken(token) {
    return apiInstance.post('/refresh', { token: token });
  }
  static getPermisos() {
    return apiInstance.get('/permisos');
  }
}
export { AuthService };